'use strict';

module.exports = {
    onMouseEnter: function () {
        $('body').on('mouseenter', '.product .tile-can-hover', function (e) {
            if (!$(this).parent().parent().hasClass('suggestion-section-body'))
                $(this).children().find('.tile-add-to-cart').removeClass('d-md-none');
        });
    },
    onMouseLeave: function () {
        $('body').on('mouseleave', '.product .tile-can-hover', function (e) {
            $(this).children().find('.tile-add-to-cart').addClass('d-md-none');
        });
    },
};
