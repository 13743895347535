'use strict';

/**
* Closes the SalesFire search component if it is currently open.
* This function selects the SalesFire search element from the DOM and, if found,
* calls the `close` method on its Vue.js parent component.
*/
function closeSalesFireSearch() {
    const salesFireSearch = document.querySelector('#salesfire-search div');

    if (salesFireSearch) {
        salesFireSearch.__vue__.$parent.close();
    }
}

/**
* Disables vertical scrolling for the search block content if the SalesFire search element is present.
* This function checks for the existence of an element with the ID 'salesfire-search' and, if found,
* sets the CSS property 'overflow-y' to 'hidden' for elements with the class 'search-block-content'.
*/
function salesFireSearchStopScroll() {
    const salesFireSearch = document.querySelector('#salesfire-search');

    if (salesFireSearch) {
        $('.search-block-content').css('overflow-y', 'hidden');
    }
}

module.exports = {
    changeHeaderColor: function () {
        $(window).on('scroll', function () {
            if ($(window).scrollTop() > 48) {
                $('.header-main-wrapper').addClass('active');
            } else {
                $('.header-main-wrapper').removeClass('active');
            }
        });
    },

    openSearch: function () {
        $('.search-icon').on('click', function () {
            $('.search-block-content').removeClass('d-none');
            $('.header-main-wrapper').addClass('d-none');
            $('html').css({'overflow-y': 'hidden'});
            $('.search-block-content').find('.search-field').trigger('focus');
            salesFireSearchStopScroll();
        });

        $('.close-search-button').on('click', function () {
            $('.search-block-content').addClass('d-none');
            $('.header-main-wrapper').removeClass('d-none');
            $('html').css({'overflow-y': 'unset'});
            closeSalesFireSearch();
        });
    },


    /**
    * Initializes and configures the header banner slider using the Slick library.
    * If the header banner slider element is not found, the function exits early.
    * The slider is set to autoplay with a speed of 3500ms and hides arrows on screens smaller than 768px.
    */
    headerBanner: function () {
        const $headerBannerSlider = $('.header-banner-slider');

        if (!$headerBannerSlider || $headerBannerSlider.length === 0) {
            return;
        }

        $headerBannerSlider.slick({
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: true,
            autoplaySpeed: 3500,
            adaptiveHeight: true,
            responsive: [
                {
                    breakpoint: 991,
                    settings: {
                        arrows: false
                    }
                }
            ]
        });
    }
};
