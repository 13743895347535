"use strict";

const SOR_WRAPPER = ".js-sor-wrapper";
const SOR_INTERVAL = ".js-sor-interval";
const SOR_INTERVAL_PDP = ".js-sor-interval-pdp";
const SOR_INTERVAL_SELECTOR = "#SorDeliveryWeekInterval";
const SOR_INTERVAL_DEFAULT = ".js-sor-interval-default";
const SOR_SWITCH = ".js-sor-switch";
const SOR_SWITCH_MESSAGE = ".js-sor-switch-message";
const SOR_SWITCH_ALL = ".js-sor-switch-all";

var Lightpick = require("../components/lightpick");
var addressHelpers = require('../checkout/address');
var moment = require("moment");
var datePicker;

module.exports = {
    init: function () {
        if ($(".subscription-checkout-details").attr("data-disabled") == 'true') {
            disable();
        } else {
            bindSwitchAllChange();
            bindSwitchChange();
            bindIntervalSelection();
            subscriptionDetailsBack();
            toggleProductsSection();
            addNewSubscriptionAddress();
            addNewSubscriptionAddressManually();
            selectExistingSubscriptionAddress();
            findAccountSubscriptionAddress();
            findAccountSubscriptionBillingAddress();
            weekChange();
            deliveryInstructions();
            shippingMethodFeatures();
            initDatePicker();
            addNewCreditCard();
            addNewOrSelectExisting();
            selectExistingCreditCard();
            paymentTabs();
            paypalSelectEvent();

            if ($(SOR_SWITCH_ALL).length != 0 && $(SOR_SWITCH).length != 0) {
                let sorActiveProducts = $(SOR_SWITCH).filter(":checked").length,
                    sorProducts = $(SOR_SWITCH).length;

                $(SOR_SWITCH_ALL).prop(
                    "checked",
                    sorActiveProducts === sorProducts
                );
            }
        }
    },
};

function disable() {
    $('.js-btn-add-new-card').addClass('d-none');
    $('.js_braintree_accountPaypalButton_wrapper').addClass('d-none');
    $('.subscription-checkout-details :input').prop("disabled", true);
    $('.pause-subscription-btn').prop("disabled", true);
    $('.add-products-to-subscription').prop("disabled", true);
    $('.remove-subscription-products').prop("disabled", true);
    $('.product-quantity :button').prop("disabled", true);
    $('.configurations :input').prop("disabled", true);
    $('.manage-subscription :input').prop("disabled", true);
    $('.save-subscription-changes').prop("disabled", true);
    $('.js-account-btn-add-new').addClass('d-none');
    $('.form-check').css('opacity', '0.4');
    $('.payment-information').click(false);
    toggleProductsSection();
    addNewOrSelectExisting();
    addNewCreditCard();
    subscriptionDetailsBack();
}

function bindSwitchChange() {
    $(document).on("change", SOR_SWITCH, function () {
        const $switch = $(this);

        return beforeUpdate()
            .then(function () {
                return doSwitchChange($switch);
            })
            .then(function () {
                // Lets switch the "Switch all", if needed
                let sorActiveProducts = $(SOR_SWITCH).filter(":checked").length,
                    sorProducts = $(SOR_SWITCH).length;

                $(SOR_SWITCH_ALL).prop(
                    "checked",
                    sorActiveProducts === sorProducts
                );
            })
            .catch(function(e) {
                console.error(e);
            })
            .finally(function () {
                return afterUpdate();
            });
    });
}

function weekChange() {
    $(document).on('change', '.OsfSorRefillProductYes', function () {
        let $message = $(SOR_SWITCH_MESSAGE);
        if (this.checked) {
            let $interval = $(SOR_INTERVAL_PDP);
            let $button = $interval.filter(".active");
            if ($button.length <= 0) {
                $button = $interval.filter(SOR_INTERVAL_DEFAULT);
            }

            $interval.removeClass("active");
            $button.addClass("active");

            $message.text($message.data('message-on'));
        } else {
            $message.text($message.data('message-off'));
        }
    });

    $(document).on("click", SOR_INTERVAL_PDP, function () {
        const $button = $(this);
        let value = $button.data('value');

        $(SOR_INTERVAL_PDP).removeClass('active');
        $button.addClass('active');
        $(SOR_INTERVAL_SELECTOR).val(value).change();
    });
}

/**
 *
 * @param {JQuery<HTMLElement>} $switch
 */
function doSwitchChange($switch) {
    const $sor = $switch.parents(SOR_WRAPPER);
    const updateCartUrl = $sor.data("action-url");

    /** @type {Promise} */
    let updatesStatus;

    if (!$switch.is(":checked")) {
        updatesStatus = doIntervalSelection(
            null,
            null,
            "remove",
            updateCartUrl
        );
    } else {
        // Lets obtain which interval should be active (either previoulsy selected or default one)
        let $intervals = $sor.find(SOR_INTERVAL);
        let $button = $intervals.filter(".active");
        if ($button.length <= 0) {
            if (sessionStorage.getItem('chosenInterval')) {
                $button = $intervals.filter('[data-interval="' + sessionStorage.getItem('chosenInterval') + '"]');
            } else {
                $button = $intervals.filter(SOR_INTERVAL_DEFAULT);
            }
        }

        // UI updates
        $intervals.removeClass("active");
        $button.addClass("active");

        // And do the actual selection
        updatesStatus = doIntervalSelection(
            "week",
            $button.data("interval"),
            "update",
            updateCartUrl
        );
    }

    return updatesStatus.then(function() {
        let $message = $sor.find(SOR_SWITCH_MESSAGE);

        if ($switch.prop('checked')) {
            $message.text($message.data('message-on'));
        } else {
            $message.text($message.data('message-off'));
        }

        return Promise.resolve();
    });
}

function bindSwitchAllChange() {
    $(document).on("click", SOR_SWITCH_ALL, function () {
        const $switchAllButton = $(this);

        return beforeUpdate()
            .then(function () {
                let $switchers = $(SOR_SWITCH);

                if ($switchAllButton.is(":checked")) {
                    $switchers = $switchers.filter(":not(:checked)");
                } else {
                    $switchers = $switchers.filter(":checked");
                }

                /** @type {Promise[]} */
                let listOfUpdates = [];

                $switchers.each(function(idx) {
                    let $switch = $($switchers.get(idx));
                    let oldCheckedValue = $switch.prop('checked');
                    $switch.prop('checked', !oldCheckedValue);

                    listOfUpdates.push(doSwitchChange($switch));
                })

                return Promise.all(listOfUpdates);
            })
            .catch(function(e) {
                console.error(e);
            })
            .finally(function () {
                return afterUpdate();
            });
    });
}

function doIntervalSelection(
    intervalType,
    intervalPeriodicity,
    action,
    updateCartUrl
) {
    let formData = new FormData();
    formData.set("action", action);
    formData.set("everyDelivery", intervalType);
    formData.set("SorDeliveryWeekInterval", intervalPeriodicity);
    formData.set("hasSmartOrderRefill", true);

    return fetch(updateCartUrl, {
        method: "POST",
        body: formData,
    }).then(function (response) {
        return response.json();
    }).then(function(data) {
        if (data.success) {
            return Promise.resolve(data);
        } else {
            return Promise.reject(data);
        }
    }).catch(function(error) {
        return Promise.reject(error);
    });
}

function bindIntervalSelection() {
    $(document).on("click", SOR_INTERVAL, function () {
        // Product container
        let $sor = $(this).parents(SOR_WRAPPER);

        // UI updates
        $sor.find(SOR_INTERVAL).removeClass("active");
        $(this).addClass("active");

        // Gathering data
        const intervalPeriodicity = $(this).data("interval");
        const $sorWrapper = $(this).parents(SOR_WRAPPER);
        const cartUpdateURL = $sorWrapper.data("action-url");

        // Actual selection
        return beforeUpdate()
            .then(function () {
                return doIntervalSelection(
                    "week",
                    intervalPeriodicity,
                    "update",
                    cartUpdateURL
                );
            })
            .catch(function(e) {
                console.error(e);
            })
            .finally(function () {
                return afterUpdate();
            });
    });
}

function beforeUpdate() {
    const isCartPage = $('.cart-page').length > 0;
    const isPDP = $('.pdp-container').length > 0;
    const isQuickview = $('.product-quickview').length > 0;
    return new Promise(function (resolve) {
        if (isCartPage || isPDP || isQuickview) {
            $.spinner().start();
        } else {
            $(".minicart .popover").addClass("show");
            $(".minicart-background").addClass("active");
            $(".minicart .popover").spinner().start();
        }
        resolve();
    });
}

function afterUpdate() {
    const isCartPage = $('.cart-page').length > 0;
    return new Promise(function (resolve) {
        if (isCartPage) {
            location.reload()
            resolve();
        } else {
            const urlMiniCart = $(".minicart").data("action-url");

            fetch(urlMiniCart, { method: "GET" })
                .then(function (response) {
                    return response.text();
                })
                .then(function (miniCartHtml) {
                    $(".minicart .popover").empty();
                    $(".minicart .popover").append(miniCartHtml);
                    $(".minicart .popover").spinner().stop();
                    resolve();
                });
        }
    });
}

function subscriptionDetailsBack() {
    $('.js-historyback-sor').on('click', function () {
        var url = $(this).data('url');
        if (url) {
            window.location.href = url;
        } else {
            window.history.back();
        }
    });
}

function toggleProductsSection() {
    $('.js-default .title, .js-active .title').on('click', function () {
        $(this).closest('.js-card-item').toggleClass('js-default').toggleClass('js-active');

        if ($(this).closest('.js-card-item').hasClass('payment-method') && $('.payment-information').attr('data-payment-method-id') == "CREDIT_CARD") {
            $('.braintreeCreditCardBtn').hide();
        }
    });
}

function addNewSubscriptionAddress() {
    $('.js-account-btn-add-new').on('click', function () {
        if ($(this).parents('.billing-address-block').length > 0) {
            $('.billing-address-block .js-account-btn-add-manual').removeClass('d-none');
            $('.billing-address-block .js-find-payment-address').removeClass('d-none');
            $('.billing-address-block #billingAddressSelector').removeClass('d-none');
            $('.billing-address-block .js-account-btn-add-new').addClass('d-none');
        } else {
            $('.delivery-address-info .js-search-by-zipcode').removeClass('d-none');
            $('.delivery-address-info .js-account-address-selector').removeClass('d-none');
            $('.delivery-address-info .js-account-btn-add-manual').removeClass('d-none');
            $('.delivery-address-info .js-account-btn-add-new').addClass('d-none');
        }
    });
}

function addNewSubscriptionAddressManually() {
    $('.js-account-btn-add-manual').on('click', function () {
        if ($(this).parents('.billing-address-block').length > 0) {
            $('.billing-address-block .js-account-btn-select-existing').removeClass('d-none');
            $('.billing-address-block .js-find-payment-address').removeClass('d-none');
            $('.billing-address-block .js-account-btn-add-manual').addClass('d-none');
            $('.billing-address-block #billingAddressSelector').addClass('d-none');

            $('.js-billingAddressOne').removeClass('d-none');
            $('.js-billingAddressTwo').removeClass('d-none');
            $('.js-billingAddressCity').removeClass('d-none');
            $('.js-billingCounty').removeClass('d-none');
        } else {
            $('.delivery-address-info .js-search-by-zipcode').removeClass('d-none');
            $('.delivery-address-info .js-account-address-selector').addClass('d-none');
            $('.delivery-address-info .js-account-btn-add-manual').addClass('d-none');
            $('.delivery-address-info .js-account-btn-select-existing').removeClass('d-none');

            $('.delivery-address-info .js-shippingAddressOne').removeClass('d-none');
            $('.delivery-address-info .js-shippingAddressTwo').removeClass('d-none');
            $('.delivery-address-info .js-shippingAddressCity').removeClass('d-none');
            $('.delivery-address-info .js-shippingCounty').removeClass('d-none');
        }
    });
}

function selectExistingSubscriptionAddress() {
    $('.js-account-btn-select-existing').on('click', function () {
        if ($(this).parents('.billing-address-block').length > 0) {
            $('.billing-address-block .js-account-btn-select-existing').addClass('d-none');
            $('.billing-address-block .js-account-btn-add-new').removeClass('d-none');
            $('.billing-address-block #billingAddressSelector').removeClass('d-none');
            $('.billing-address-block .js-find-payment-address').addClass('d-none');

            $('.js-billingAddressOne').addClass('d-none');
            $('.js-billingAddressTwo').addClass('d-none');
            $('.js-billingAddressCity').addClass('d-none');
            $('.js-billingCounty').addClass('d-none');
        } else {
            $('.delivery-address-info .js-search-by-zipcode').addClass('d-none');
            $('.delivery-address-info .js-account-address-selector').removeClass('d-none');
            $('.delivery-address-info .js-account-btn-select-existing').addClass('d-none');
            $('.delivery-address-info .js-account-btn-add-new').removeClass('d-none');

            $('.delivery-address-info .js-shippingAddressOne').addClass('d-none');
            $('.delivery-address-info .js-shippingAddressTwo').addClass('d-none');
            $('.delivery-address-info .js-shippingAddressCity').addClass('d-none');
            $('.delivery-address-info .js-shippingCounty').addClass('d-none');
        }
    });
}

function findAccountSubscriptionAddress() {
    $('.js-btn-find-address').on('click', function () {
        var zipCodeText = $('#shippingZipCodedefault').val();
        var pattern = new RegExp($('#shippingZipCodedefault').attr('pattern'));
        if (pattern && zipCodeText && pattern.test(zipCodeText)) {
            $.ajax({
                url: $(this).attr('data-url'),
                type: 'post',
                data: {
                    postCode: zipCodeText
                },
                success: function (data) {
                    var $addressSelector = $('#shipmentSelector-default');
                    for (var i = 0; i < data.addresses.length; i++) {
                        var address = data.addresses[i];
                        var addressLine1 = [address.building_number, address.thoroughfare].filter(Boolean).join(' ');
                        var addressLine2 = [address.building_name, address.sub_building_name, address.sub_building_number].filter(Boolean).join(', ');
                        var city = address.town_or_city;
                        var county = address.county;

                        var option = '<option value="' + address.stringAddress + '" ' +
                                    'data-address1="' + addressLine1 + '" ' +
                                    'data-address2="' + addressLine2 + '" ' +
                                    'data-city="' + city + '" ' +
                                    'data-postal-code="' + zipCodeText + '" ' +
                                    'data-state-code="' + county + '">' +
                                    address.stringAddress + '</option>';
                        $addressSelector.append(option);
                    }
                }
            });
        }
    });
}

function findAccountSubscriptionBillingAddress() {
    $('.js-find-payment-address button.btn-find-address').on('click', function () {
        var zipCodeText = $('#billingZipCode').val();
        var pattern = new RegExp($('#billingZipCode').attr('pattern'));
        if (pattern && zipCodeText && pattern.test(zipCodeText)) {
            $.ajax({
                url: $('.js-find-payment-address').attr('data-url'),
                type: 'post',
                data: {
                    postCode: zipCodeText
                },
                success: function (data) {
                    var $addressSelector = $('#billingAddressSelector');
                    for (var i = 0; i < data.addresses.length; i++) {
                        var address = data.addresses[i];
                        var addressLine1 = [address.building_number, address.thoroughfare].filter(Boolean).join(' ');
                        var addressLine2 = [address.building_name, address.sub_building_name, address.sub_building_number].filter(Boolean).join(', ');
                        var city = address.town_or_city;
                        var county = address.county;

                        var option = '<option value="' + address.stringAddress + '" ' +
                                    'data-address1="' + addressLine1 + '" ' +
                                    'data-address2="' + addressLine2 + '" ' +
                                    'data-city="' + city + '" ' +
                                    'data-postal-code="' + zipCodeText + '" ' +
                                    'data-state-code="' + county + '">' +
                                    address.stringAddress + '</option>';
                        $addressSelector.append(option);
                    }
                }
            });
        }
    });
}

/**
 * Init date picker for shipping methods
 */
function initDatePicker() {
    var availableDates = $('.shipping-method-list-edit').attr("data-available-dates");

    if (availableDates) {
        var enabledDates = JSON.parse(availableDates);

        if (datePicker) {
            datePicker.reloadOptions({
                enabledDates: enabledDates
            });
        } else {
            datePicker = new Lightpick({
                field: document.getElementById('shippingDeliveryDateEdit'),
                singleDate: true,
                enabledDates: enabledDates,
                minDate: moment().startOf("day").add(1, "days"),
                maxDate: moment().startOf("day").add(32, "days"),
                onSelect: function () {
                    $('.shippingDeliveryDateEdit').trigger('change');
                }
            });

            $('.calendar-icon').on('click', function (e) {
                e.stopPropagation();
                $('.shippingDeliveryDateEdit').trigger('click');
            });
        }
    }
}

function deliveryInstructions() {
    $('select.shippingInstructionsOptions').on('change', function () {
        var $this = $(this);

        if ($this.val().toLowerCase() == 'other') {
            $('.js-shipping-instructions-text').removeClass('d-none');
        } else {
            $('.js-shipping-instructions-text').addClass('d-none');
        }
    });
}

function shippingMethodFeatures() {
    $('.shipping-method-list-edit').change(function () {
        var $form = $(this).parents('form');
        var methodID = $(':checked', this).val();
        var shipmentUUID = $form.find('[name=shipmentUUID]').val();
        var urlParams = addressHelpers.methods.getAddressFieldsFromUI($('.delivery-address-info'));
        urlParams.shipmentUUID = shipmentUUID;
        urlParams.methodID = methodID;

        var url = $(this).data('select-shipping-method-url');
        $.spinner().start();
        $.ajax({
            url: url,
            type: 'post',
            dataType: 'json',
            data: urlParams,
            success: function (data) {
                if (data.error) {
                    window.location.href = data.redirectUrl;
                } else {
                    $('.order-total .shipping .value').empty().text(data.totalsModel.totalShippingCost);
                    $('.total .js-grand-total').empty().text(data.totalsModel.grandTotal);
                    $('.total .js-grand-total').attr('data-grand-total-value', data.totalsModel.grandTotalValue);
                    $('.shipping-method-list-edit').attr('data-available-dates', JSON.stringify(data.shippingModel.availableDates));
                    $('.shippingMethodID').val(data.shippingModel.selectedShippingMethod.ID);
                    $('.shippingDeliveryDateEdit').val('');
                    initDatePicker();
                    $.spinner().stop();
                }
            },
            error: function (err) {
                $.spinner().stop();
            }
        });
    });
}

function addNewOrSelectExisting() {
    if ($('.braintree_used_creditcard_account').hasClass('used-creditcard-account-hide')) {
        $('.js-btn-add-new-card').hide();
        if ($('#braintreeCreditCardList option').length > 3) {
            $('.js-btn-select-existing-card').show();
        } else {
            $('.js-btn-select-existing-card').hide();
        }
        $('#braintreeCreditCardList').hide();
        $('.js_braintree_creditCardFields').show();
        $('#braintreeSaveCardContainer').removeClass('hidden');
        $('#braintreeSaveCardContainer').addClass('d-flex');
    } else {
        $('.js-btn-select-existing-card').hide();
        $('.js-btn-add-new-card').show();
        $('#braintreeCreditCardList').show();
        $('.js_braintree_creditCardFields').hide();
        $('#braintreeSaveCardContainer').addClass('hidden');
        $('#braintreeSaveCardContainer').removeClass('d-flex');
    }
}

function addNewCreditCard() {
    $('.js-btn-add-new-card').on('click', function() {
        var braintreeCreditCardList = $('#braintreeCreditCardList');
        braintreeCreditCardList.val('newcard').show();
        braintreeCreditCardList.val('newcard').change();
        braintreeCreditCardList.hide();
        var $creditCardList = document.querySelector('#braintreeCreditCardList');
        var evt = document.createEvent("HTMLEvents");
        evt.initEvent('change', false, true);
        $creditCardList.dispatchEvent(evt);
        braintreeCreditCardList.val('newcard').hide();
        $('.js-btn-add-new-card').hide();
        $('.braintreeCreditCardBtn').show();
        $('.js-btn-select-existing-card').show();
        $('.js_braintree_creditCardFields').show();
        $('#braintreeSaveCardContainer').removeClass('hidden');
        $('#braintreeSaveCardContainer').addClass('d-flex');
    });
}

function selectExistingCreditCard() {
    $('.js-btn-select-existing-card').on('click', function() {
        if (!$('.braintree_used_creditcard_account').hasClass('used-creditcard-account-hide')) {
            var savedCards = $('#braintreeCreditCardList option').length;
            $($('#braintreeCreditCardList option')[savedCards-1]).prop('selected', true);
            $('#braintreeCreditCardList').show();
            var $creditCardList = document.querySelector('#braintreeCreditCardList');
            var evt = document.createEvent("HTMLEvents");
            evt.initEvent('change', false, true);
            $creditCardList.dispatchEvent(evt);
        }
        $('.js-btn-add-new-card').show();
        $('.braintreeCreditCardBtn').hide();
        $('.js-btn-select-existing-card').hide();
        $('.js_braintree_creditCardFields').hide();
        $('#braintreeSaveCardContainer').addClass('hidden');
        $('#braintreeSaveCardContainer').removeClass('d-flex');
    });
}

function paymentTabs() {
    $('.payment-options .nav-item').on('click', function () {
        var methodID = $(this).data('method-id');
        $('.payment-information').attr('data-payment-method-id', methodID);

        if (methodID === 'CREDIT_CARD' && !$('.braintree_used_creditcard_account').hasClass('used-creditcard-account-hide')) {
            $('.braintreeCreditCardBtn').hide();
            var savedCards = $('#braintreeCreditCardList option').length;
            $($('#braintreeCreditCardList option')[savedCards-1]).prop('selected', true);
            $('#braintreeCreditCardList').show();
            var $creditCardList = document.querySelector('#braintreeCreditCardList');
            var evt = document.createEvent("HTMLEvents");
            evt.initEvent('change', false, true);
            $creditCardList.dispatchEvent(evt);
        }
    });
}

function paypalSelectEvent() {
    $('#braintreePaypalAccountsList').on('change', function () {
        if ($('#braintreePaypalAccountsList').find(":selected").hasClass('js-stored-paypal-account')) {
            $('#paypal-content .card-footer').addClass('d-none');
        } else {
            $('#paypal-content .card-footer').removeClass('d-none');
        }
    });
}
