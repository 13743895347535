'use strict';

module.exports = function () {
    var collapsedTitle = $('.menu-title');
    var menuLinksWrapper = $('.privacy-item-links');
    var menuLink = $('.privacy-item-links li');

    menuLink.on('click', function (e) {
        e.preventDefault();

        var $this = $(this);
        var $activeLinkTitle = $this.find('a').html();

        // on mobile when menu is collapsed, add title of selected menu link to the element above the menu
        if (window.innerWidth < 544) {
            $this.hide();
            menuLinksWrapper.slideUp(300).removeClass('expanded');
            collapsedTitle.find('span').text($activeLinkTitle);
            collapsedTitle.removeClass('hide');
        }
        
        menuLink.not($(this)).removeClass('active');
        $this.addClass('active');
        
        // show related answers when clicking on submenu title
        var href = $this.find('a').attr('href');
        $('.privacy-block').hide();
        $('.privacy-block'+ href + '').show();
        window.scrollTo({top: 0, behavior: 'smooth'});
    });

    collapsedTitle.on('click',  function () {
        if (window.innerWidth < 544) {
            menuLinksWrapper.slideToggle(300).toggleClass('expanded');

            if (menuLinksWrapper.hasClass('expanded')) {
                menuLinksWrapper.find('li.active').show()
                collapsedTitle.find('span').text('');
                collapsedTitle.addClass('hide');
            }
        }
    })
};

