'use strict';

var URLParameters = ['minimumWeight'];

function removeParamsFromUrl(url, params) {
    var urlObj = new URL(url);

    // Iterate over the array of params and remove each one based on its key
    params.forEach(function (param) {
        if (urlObj.searchParams.has(param.key)) {
            urlObj.searchParams.delete(param.key);
        }
    });

    // Return the modified URL as a string
    return urlObj.toString();
}

module.exports = function () {
    var queryString = window.location.search.substring(1);
    var params = queryString.split('&');
    var foundParams = [];

    for (var i = 0; i < params.length; i++) {
        var param = params[i].split('=');
        if (URLParameters.includes(param[0])) {
            foundParams.push({key: param[0], value: param[1]});
        }
    }

    if (foundParams.length > 0) {
        $.ajax({
            url: $('#sessionVariablesUrl').attr('data-url'),
            type: 'POST',
            contentType: 'application/json',
            data: JSON.stringify(foundParams),
        }).done(function () {
            window.location.href = removeParamsFromUrl(window.location.href, foundParams);
        });
    }
};
