'use strict';

module.exports = function () {
    var $backButton = $('.back-to-questions');
    var $faqNav = $('.js-faq-nav');
    var $faqBlock = $('.faq-block');
    var $faqNavBlock = $('.content-header, .menu-item');

    // open/close submenu on main menu title click and open/close answers on answer title click
    $faqNav.on('click', function () {
        var $this = $(this);
        $this.next().slideToggle(200);
        $this.toggleClass('open', 200);
    });

    $('.menu-item-links a').on('click', function (e) {
        e.preventDefault();
       
        var $this = $(this);
        // show related answers when clicking on submenu title
        var href = $this.attr('href');
        $faqBlock.hide();
        $('.faq-block'+ href + '').show();

        if (window.innerWidth < 544) {
            //close expanded menu on mobile when submenu title is clicked
            $this.closest('ul.menu-item-links').slideUp(200);
            $faqNav.removeClass('open');
            $faqNavBlock.hide();
            $backButton.show();
            $this.closest('div.menu-item').show();
        }
    });
    
    $backButton.on('click', function () {
        $(this).hide();
        $faqBlock.hide();
        $faqNavBlock.show();
    })
};
