'use strict';
var focusHelper = require('../components/focus');

/**
 * Retrieves the relevant pid value
 * @param {jquery} $el - DOM container for a given add to cart button
 * @return {string} - value to be used when adding product to cart
 */
function getPidValue($el) {
    var pid;

    if ($('#quickViewModal').hasClass('show') && !$('.product-set').length) {
        pid = $($el).closest('.modal-content').find('.product-quickview').data('pid');
    } else if ($('.product-set-detail').length || $('.product-set').length) {
        pid = $($el).closest('.product-detail').find('.product-id').text();
    } else if ($('.pdp-bundle-selector .bundle-selector').find(".selected")) {
        pid = $('.pdp-bundle-selector .bundle-selector').find(".selected").attr('data-custom-pid');
    } else {
        pid = $('.product-detail:not(".bundle-item")').data('pid');
    }

    return pid;
}

/**
 * Retrieve contextual quantity selector
 * @param {jquery} $el - DOM container for the relevant quantity
 * @return {jquery} - quantity selector DOM container
 */
function getQuantitySelector($el) {
    return $el.closest('.product-detail').find('.pdp-quantity input.quantity');
}

/**
 * Retrieves the value associated with the Quantity pull-down menu
 * @param {jquery} $el - DOM container for the relevant quantity
 * @return {string} - value found in the quantity input
 */
function getQuantitySelected($el) {
    return getQuantitySelector($el).val();
}

/**
 * Process the attribute values for an attribute that has image swatches
 *
 * @param {Object} attr - Attribute
 * @param {string} attr.id - Attribute ID
 * @param {Object[]} attr.values - Array of attribute value objects
 * @param {string} attr.values.value - Attribute coded value
 * @param {string} attr.values.url - URL to de/select an attribute value of the product
 * @param {boolean} attr.values.isSelectable - Flag as to whether an attribute value can be
 *     selected.  If there is no variant that corresponds to a specific combination of attribute
 *     values, an attribute may be disabled in the Product Detail Page
 * @param {jQuery} $productContainer - DOM container for a given product
 * @param {Object} msgs - object containing resource messages
 */
function processSwatchValues(attr, $productContainer, msgs) {
    attr.values.forEach(function (attrValue) {
        var $attrValue = $productContainer.find('[data-attr="' + attr.id + '"] [data-attr-value="' +
            attrValue.value + '"]');
        var $swatchButton = $attrValue.parent();

        if (attrValue.selected) {
            $attrValue.addClass('selected');
            $attrValue.siblings('.selected-assistive-text').text(msgs.assistiveSelectedText);
        } else {
            $attrValue.removeClass('selected');
            $attrValue.siblings('.selected-assistive-text').empty();
        }

        if (attrValue.url) {
            $swatchButton.attr('data-url', attrValue.url);
        } else {
            $swatchButton.removeAttr('data-url');
        }

        // Disable if not selectable
        $attrValue.removeClass('selectable unselectable');

        $attrValue.addClass(attrValue.selectable ? 'selectable' : 'unselectable');
    });
}

/**
 * Process attribute values associated with an attribute that does not have image swatches
 *
 * @param {Object} attr - Attribute
 * @param {string} attr.id - Attribute ID
 * @param {Object[]} attr.values - Array of attribute value objects
 * @param {string} attr.values.value - Attribute coded value
 * @param {string} attr.values.url - URL to de/select an attribute value of the product
 * @param {boolean} attr.values.isSelectable - Flag as to whether an attribute value can be
 *     selected.  If there is no variant that corresponds to a specific combination of attribute
 *     values, an attribute may be disabled in the Product Detail Page
 * @param {jQuery} $productContainer - DOM container for a given product
 */
function processNonSwatchValues(attr, $productContainer) {
    var $attr = '[data-attr="' + attr.id + '"]';
    var $defaultOption = $productContainer.find($attr + ' .select-' + attr.id + ' option:first');
    $defaultOption.attr('value', attr.resetUrl);

    attr.values.forEach(function (attrValue) {
        var $attrValue = $productContainer
            .find($attr + ' [data-attr-value="' + attrValue.value + '"]');
        $attrValue.attr('data-url', attrValue.url)
            .removeAttr('disabled');

        if (!attrValue.selectable) {
            $attrValue.attr('disabled', true);
        }
    });
}

/**
 * Routes the handling of attribute processing depending on whether the attribute has image
 *     swatches or not
 *
 * @param {Object} attrs - Attribute
 * @param {string} attr.id - Attribute ID
 * @param {jQuery} $productContainer - DOM element for a given product
 * @param {Object} msgs - object containing resource messages
 */
function updateAttrs(attrs, $productContainer, msgs) {
    // Currently, the only attribute type that has image swatches is Color.
    var attrsWithSwatches = ['color'];

    attrs.forEach(function (attr) {
        if (attrsWithSwatches.indexOf(attr.id) > -1) {
            processSwatchValues(attr, $productContainer, msgs);
        } else {
            processNonSwatchValues(attr, $productContainer);
        }
    });
}

/**
 * Updates the availability status in the Product Detail Page
 *
 * @param {Object} response - Ajax response object after an
 *                            attribute value has been [de]selected
 * @param {jQuery} $productContainer - DOM element for a given product
 */
function updateAvailability(response, $productContainer) {
    var availabilityValue = '';
    var availabilityMessages = response.product.availability.messages;
    if (!response.product.readyToOrder) {
        availabilityValue = '<li><div>' + response.resources.info_selectforstock + '</div></li>';
    } else {
        availabilityMessages.forEach(function (message) {
            availabilityValue += '<li><div>' + message + '</div></li>';
        });
    }

    // Update max value
    if (response.product.availability.isPerpetual) {
        $('.pdp-quantity input.quantity').removeAttr('max');
    } else {
        $('.pdp-quantity input.quantity').attr('max', response.product.availability.stockLevel);
    }

    // Variant has no stock
    if (!response.product.readyToOrder) {
        $productContainer.find('.pdp-out-of-stock').show();
    } else {
        $productContainer.find('.pdp-out-of-stock').hide();
    }

    // Variant has less stock than qty input
    var $input = $('.number-input-input-wrapper').find('input.quantity');
    var val = parseInt($input.val());
    var maxQty = parseInt($input.attr('max'));

    $('.pdp-out-of-stock .alert-text').html(response.product.availability.stockLevelMessage);
    $('.pdp-out-of-stock .alert-text span').html(response.product.productName);
    $('.pdp-out-of-stock').hide();
    if (!(val <= maxQty || isNaN(maxQty))) {
        $('.pdp-out-of-stock').show();
    }

    $($productContainer).trigger('product:updateAvailability', {
        product: response.product,
        $productContainer: $productContainer,
        message: availabilityValue,
        resources: response.resources
    });
}

/**
 * Generates html for product attributes section
 *
 * @param {array} attributes - list of attributes
 * @return {string} - Compiled HTML
 */
function getAttributesHtml(attributes) {
    if (!attributes) {
        return '';
    }

    var html = '';

    attributes.forEach(function (attributeGroup) {
        if (attributeGroup.ID === 'mainAttributes') {
            attributeGroup.attributes.forEach(function (attribute) {
                html += '<div class="attribute-values">' + attribute.label + ': '
                    + attribute.value + '</div>';
            });
        }
    });

    return html;
}

/**
 * @typedef UpdatedOptionValue
 * @type Object
 * @property {string} id - Option value ID for look up
 * @property {string} url - Updated option value selection URL
 */

/**
 * @typedef OptionSelectionResponse
 * @type Object
 * @property {string} priceHtml - Updated price HTML code
 * @property {Object} options - Updated Options
 * @property {string} options.id - Option ID
 * @property {UpdatedOptionValue[]} options.values - Option values
 */

/**
 * Updates DOM using post-option selection Ajax response
 *
 * @param {OptionSelectionResponse} optionsHtml - Ajax response optionsHtml from selecting a product option
 * @param {jQuery} $productContainer - DOM element for current product
 */
function updateOptions(optionsHtml, $productContainer) {
	// Update options
    $productContainer.find('.product-options').empty().html(optionsHtml);
}

/**
 * Dynamically creates Bootstrap carousel from response containing images
 * @param {Object[]} imgs - Array of large product images,along with related information
 * @param {string} backgroundImage - URL of the background image
 * @param {jQuery} $productContainer - DOM element for a given product
 */
function createCarousel(imgs, $productContainer) {
    if (!$productContainer.hasClass('product-quickview')) {
        var smallPDPCarousel = $productContainer.find('.pdp-main-swiper-small');
        var smallPDPCarouselSwiper = smallPDPCarousel[0].swiper;
        var gallery = $productContainer.find('.gallery');
        var imageBlockThumbnails = $productContainer.find('.image-block__thumbnails');
        var imageBlockThumbnailsBackgroundColor = imageBlockThumbnails.find('.image-block__thumbnail').css('background-color');
        var imageBlock = $productContainer.find('.image-block__image');
        var smallPDPCarouselPagination = $productContainer.find('.pdp-main-swiper-small-pagination');

        smallPDPCarouselSwiper.removeAllSlides();
        gallery.empty();
        imageBlockThumbnails.empty();
        imageBlock.empty();

        for (var i = 0; i < imgs.length; i++) {
            const isFullBleedImageClass = imgs[i].fullBleed ? 'full-bleed' : '';
            const imageURL = imgs[i].fullBleed ? imgs[i].fullBleedUrl : imgs[i].url;

            smallPDPCarouselSwiper.appendSlide('<a class="swiper-slide" href="' + imgs[i].url.split('?')[0] + '"><img data-src="' + imageURL + '" class="carousel-image hidden lazyload ' + isFullBleedImageClass + '" alt="' + imgs[i].alt + ' image number ' + parseInt(imgs[i].index, 10) + '" itemprop="image" "/></a>');
            gallery.append(`<a href='${imgs[i].url.split('?')[0]}'></a>`);
            imageBlockThumbnails.append(`
                <li class="image-block__thumbnail ${i == 0 ? 'borderActive' : ''} ${imgs[i].fullBleed ? 'full-bleed' : ''}" style="background-color: ${imageBlockThumbnailsBackgroundColor || ''};">
                    <a class="${i == 0 ? 'zoomThumbActive' : ''}" href='javascript:void(0);'
                        rel="{gallery: 'gal1', smallimage: '${imgs[i].url}', largeimage: '${imgs[i].url.split('?')[0]}'}"
                    >
                        <img data-src="${imgs[i].fullBleed ? imgs[i].fullBleedUrl : imgs[i].url}" class="lazyload" alt="${imgs[i].alt} image number ${imgs[i].index}" itemprop="image"/>
                    </a>
                </li>
            `);

            if (i == 0) {
                imageBlock.append(`
                    <a href="${imgs[i].url.split('?')[0]}" class="jqzoom" rel="gal1" title="${imgs[i].alt}">
                        <img class="image-block__presentation" src="${imgs[i].url}" alt="${imgs[i].alt}" />
                    </a>
                `);
            }
        }

        smallPDPCarousel.find('.carousel-image').first().addClass('carousel-selected');
        smallPDPCarousel.parent().find('.active-slide-index').text(1);
        smallPDPCarouselSwiper.slideTo(1);
        smallPDPCarousel.find('.carousel-image').removeClass('hidden');
        smallPDPCarouselPagination.find('.totla-slides').text(imgs.length);
    } else {
        var carousel = $productContainer.find('.swiper-wrapper');
        $(carousel).empty();

        for (var j = 0; j < imgs.length; j++) {
            if (i == 0) {
                $('.main-image').attr('src', imgs[j].url);
            }
            $('<div class="swiper-slide"><img data-src="' + imgs[j].url + '" class="carousel-image lazyload" alt="' + imgs[j].alt + ' image number ' + parseInt(imgs[j].index, 10) + '" itemprop="image" width="100%" height="100%"/></div>').appendTo($(carousel));
        }
    }
}

/**
 * Parses JSON from Ajax call made whenever an attribute value is [de]selected
 * @param {Object} response - response from Ajax call
 * @param {Object} response.product - Product object
 * @param {string} response.product.id - Product ID
 * @param {Object[]} response.product.variationAttributes - Product attributes
 * @param {Object[]} response.product.images - Product images
 * @param {boolean} response.product.hasRequiredAttrsSelected - Flag as to whether all required
 *     attributes have been selected.  Used partially to
 *     determine whether the Add to Cart button can be enabled
 * @param {jQuery} $productContainer - DOM element for a given product.
 */
function handleVariantResponse(response, $productContainer) {
    var isChoiceOfBonusProducts =
        $productContainer.parents('.choose-bonus-product-dialog').length > 0;
    var isVaraint;
    if (response.product.variationAttributes) {
        updateAttrs(response.product.variationAttributes, $productContainer, response.resources);
        isVaraint = response.product.productType === 'variant';
        if (isChoiceOfBonusProducts && isVaraint) {
            $productContainer.parent('.bonus-product-item')
                .data('pid', response.product.id);

            $productContainer.parent('.bonus-product-item')
                .data('ready-to-order', response.product.readyToOrder);
        }
    }

    if ($productContainer.hasClass('product-quickview')) {
        if (response.product.customBundleTemplate) {
            $productContainer.find('.pdp-bundle-selector').html(response.product.customBundleTemplate);
        }
    } else {
        if (response.product.customPDPBundleTemplate) {
            $productContainer.find('.pdp-bundle-selector').html(response.product.customPDPBundleTemplate);
        }

        const normalPriceFormatted = response.product.price.SORprice ? response.product.price.list.formatted : response.product.price.sales.formatted;
        const salesPriceFormatted = response.product.price.SORprice ? response.product.price.sales.formatted : '';

        $('.pdp-subscription-selection-one-time-price').text(normalPriceFormatted);
        $('.pdp-subscription-selection-save-price .list').text(normalPriceFormatted);
        $('.pdp-subscription-selection-save-price .sales').text(salesPriceFormatted);
    }

    // Update primary images (without image composition)
    var primaryImageUrls = response.product.images.large;
    for (var i = 0; i < primaryImageUrls.length; i++) {
        if (primaryImageUrls[i].url.includes('composition')) {
            primaryImageUrls.splice(i,1);;
        }
    }
    createCarousel(primaryImageUrls, $productContainer);

    if ($('.pdp-food-image').length > 0 && response.product.foodImage) {
        $('.pdp-food-image').css('background-image', 'url(' + response.product.foodImage.url + ')');
    }

    // Update pricing
    if (!isChoiceOfBonusProducts) {
        var $priceSelector = $('.prices .price', $productContainer).length
            ? $('.prices .price', $productContainer)
            : $('.price .price');
        $priceSelector.replaceWith(response.product.renderedPriceQuantity);
    }

    // Update promotions
    $productContainer.find('.promotions').empty().html(response.product.promotionsHtml);

    updateAvailability(response, $productContainer);

    if (isChoiceOfBonusProducts) {
        var $selectButton = $productContainer.find('.select-bonus-product');
        $selectButton.trigger('bonusproduct:updateSelectButton', {
            product: response.product, $productContainer: $productContainer
        });
    } else {
        // Enable "Add to Cart" button if all required attributes have been selected
        $('button.add-to-cart, button.add-to-cart-global, button.update-cart-product-global').trigger('product:updateAddToCart', {
            product: response.product, $productContainer: $productContainer
        }).trigger('product:statusUpdate', response.product);
    }

    // Update attributes
    $productContainer.find('.main-attributes').empty()
        .html(getAttributesHtml(response.product.attributes));

    // update qty url
    if (response.product.quantityURL) {
        $('.pdp-quantity input.quantity').attr('data-qtyurl', response.product.quantityURL);
    }

    $('.add-to-cart').attr('data-pid', response.product.id);
    $('.product-detail').attr('data-pid', response.product.id);
}

/**
 * @typespec UpdatedQuantity
 * @type Object
 * @property {boolean} selected - Whether the quantity has been selected
 * @property {string} value - The number of products to purchase
 * @property {string} url - Compiled URL that specifies variation attributes, product ID, options,
 *     etc.
 */

/**
 * Updates the quantity DOM elements post Ajax call
 * @param {UpdatedQuantity[]} quantities -
 * @param {jQuery} $productContainer - DOM container for a given product
 */
function updateQuantities(quantities, $productContainer) {
    if ($productContainer.parent('.bonus-product-item').length <= 0) {
        var optionsHtml = quantities.map(function (quantity) {
            var selected = quantity.selected ? ' selected ' : '';
            return '<option value="' + quantity.value + '"  data-url="' + quantity.url + '"' +
                selected + '>' + quantity.value + '</option>';
        }).join('');
        getQuantitySelector($productContainer).empty().html(optionsHtml);
    }
}

/**
 * updates the product view when a product attribute is selected or deselected or when
 *         changing quantity
 * @param {string} selectedValueUrl - the Url for the selected variation value
 * @param {jQuery} $productContainer - DOM element for current product
 */
function attributeSelect(selectedValueUrl, $productContainer) {
    if (selectedValueUrl) {
        $('body').trigger('product:beforeAttributeSelect',
            { url: selectedValueUrl, container: $productContainer });

        var SORprice = $productContainer.find('.OsfSorRefillProductYes').is(':checked');

        if (!$productContainer.hasClass('product-quickview')) {
            SORprice = true;
        }

        $.ajax({
            url: selectedValueUrl + '&SORprice=' + SORprice,
            method: 'GET',
            success: function (data) {
                handleVariantResponse(data, $productContainer);
                updateOptions(data.product.optionsHtml, $productContainer);
                updateQuantities(data.product.quantities, $productContainer);
                $('body').trigger('product:afterAttributeSelect',
                    { data: data, container: $productContainer });
                if (data.product.discountPercentage) {
                    $('body .pdp-price .price-discount').html(data.product.discountPercentage + '%');
                }

                $.spinner().stop();
            },
            error: function () {
                $.spinner().stop();
            }
        });
    }
}

/**
 * Retrieves url to use when adding a product to the cart
 *
 * @return {string} - The provided URL to use when adding a product to the cart
 */
function getAddToCartUrl() {
    return $('.add-to-cart-url').val();
}

/**
 * Parses the html for a modal window
 * @param {string} html - representing the body and footer of the modal window
 *
 * @return {Object} - Object with properties body and footer.
 */
function parseHtml(html) {
    var $html = $('<div>').append($.parseHTML(html));

    var body = $html.find('.choice-of-bonus-product');
    var footer = $html.find('.modal-footer').children();

    return { body: body, footer: footer };
}

/**
 * Retrieves url to use when adding a product to the cart
 *
 * @param {Object} data - data object used to fill in dynamic portions of the html
 */
function chooseBonusProducts(data) {
    $('.modal-body').spinner().start();

    if ($('#chooseBonusProductModal').length !== 0) {
        $('#chooseBonusProductModal').remove();
    }
    var bonusUrl;
    if (data.bonusChoiceRuleBased) {
        bonusUrl = data.showProductsUrlRuleBased;
    } else {
        bonusUrl = data.showProductsUrlListBased;
    }

    var htmlString = '<!-- Modal -->'
        + '<div class="modal fade" id="chooseBonusProductModal" tabindex="-1" role="dialog">'
        + '<span class="enter-message sr-only" ></span>'
        + '<div class="modal-dialog choose-bonus-product-dialog" '
        + 'data-total-qty="' + data.maxBonusItems + '"'
        + 'data-UUID="' + data.uuid + '"'
        + 'data-pliUUID="' + data.pliUUID + '"'
        + 'data-addToCartUrl="' + data.addToCartUrl + '"'
        + 'data-pageStart="0"'
        + 'data-pageSize="' + data.pageSize + '"'
        + 'data-moreURL="' + data.showProductsUrlRuleBased + '"'
        + 'data-bonusChoiceRuleBased="' + data.bonusChoiceRuleBased + '">'
        + '<!-- Modal content-->'
        + '<div class="modal-content">'
        + '<div class="modal-header">'
        + '    <span class="">' + data.labels.selectprods + '</span>'
        + '    <button type="button" class="close pull-right" data-dismiss="modal">'
        + '        <span aria-hidden="true">&#10005;</span>'
        + '        <span class="sr-only"> </span>'
        + '    </button>'
        + '</div>'
        + '<div class="modal-body"></div>'
        + '<div class="modal-footer"></div>'
        + '</div>'
        + '</div>'
        + '</div>';
    $('body').append(htmlString);
    $('.modal-body').spinner().start();

    $.ajax({
        url: bonusUrl,
        method: 'GET',
        dataType: 'json',
        success: function (response) {
            var parsedHtml = parseHtml(response.renderedTemplate);
            $('#chooseBonusProductModal .modal-body').empty();
            $('#chooseBonusProductModal .enter-message').text(response.enterDialogMessage);
            $('#chooseBonusProductModal .modal-header .close .sr-only').text(response.closeButtonText);
            $('#chooseBonusProductModal .modal-body').html(parsedHtml.body);
            $('#chooseBonusProductModal .modal-footer').html(parsedHtml.footer);
            $('#chooseBonusProductModal').modal('show');
            $.spinner().stop();
        },
        error: function () {
            $.spinner().stop();
        }
    });
}

/**
 * Updates the Mini-Cart quantity value after the customer has pressed the "Add to Cart" button
 * @param {string} response - ajax response from clicking the add to cart button
 */
function handlePostCartAdd(response) {
    $('.minicart').trigger('count:update', response);
    var messageType = response.error ? 'alert-danger' : 'alert-success';
    // show add to cart toast
    if (response.newBonusDiscountLineItem
        && Object.keys(response.newBonusDiscountLineItem).length !== 0) {
        chooseBonusProducts(response.newBonusDiscountLineItem);
    } else if (response.flashMessage) {
        $('body').append(response.flashMessage);

        setTimeout(function () {
            $('.flash-messages').remove();
        }, 3000);
    } else {
        if ($('.add-to-cart-messages').length === 0) {
            $('body').append(
                '<div class="add-to-cart-messages"></div>'
            );
        }

        $('.add-to-cart-messages').append(
            '<div class="alert ' + messageType + ' add-to-basket-alert text-center" role="alert">'
            + response.message
            + '</div>'
        );

        setTimeout(function () {
            $('.add-to-basket-alert').remove();
        }, 5000);
    }

    if ($('.page').attr('data-action') == 'Cart-Show') {
        location.reload();
    }
}

/**
 * Retrieves the bundle product item ID's for the Controller to replace bundle master product
 * items with their selected variants
 *
 * @return {string[]} - List of selected bundle product item ID's
 */
function getChildProducts() {
    var childProducts = [];
    $('.bundle-item').each(function () {
        childProducts.push({
            pid: $(this).find('.product-id').text(),
            quantity: parseInt($(this).find('label.quantity').data('quantity'), 10)
        });
    });

    return childProducts.length ? JSON.stringify(childProducts) : [];
}

/**
 * Retrieve product options
 *
 * @param {jQuery} $productContainer - DOM element for current product
 * @return {string} - Product options and their selected values
 */
function getOptions($productContainer) {
    var options = $productContainer
        .find('.product-option')
        .map(function () {
            var $elOption = $(this).find('.options-select');
            var urlValue = $elOption.val();
            var selectedValueId = $elOption.find('option[value="' + urlValue + '"]')
                .data('value-id');
            return {
                optionId: $(this).data('option-id'),
                selectedValueId: selectedValueId
            };
        }).toArray();

    return JSON.stringify(options);
}

/**
 * Makes a call to the server to report the event of adding an item to the cart
 *
 * @param {string | boolean} url - a string representing the end point to hit so that the event can be recorded, or false
 */
function miniCartReportingUrl(url) {
    if (url) {
        $.ajax({
            url: url,
            method: 'GET',
            success: function () {
                // reporting urls hit on the server
            },
            error: function () {
                // no reporting urls hit on the server
            }
        });
    }
}

function applySORDiscount(data) {
    var product = data.product ? false : true;
    var url;

    if (product) {
        url = data.closest('.subscription-wrapper').siblings('.pdp-cart-and-price').find('input.quantity').attr('data-qtyurl');
        url = url + '&quantity=' + getQuantitySelected(data) + '&SORprice=' + data.closest('.OsfSorRefillProductYes').is(":checked");
    }

    if (data && !product) {
        return;
    }

    $.spinner().start();
    $.ajax({
        url: url,
        method: 'GET',
        success: function (response) {
            const quickView = $('.product-quickview');
            if (quickView.length > 0 && quickView.is(':visible')) {
                if (response.product.customBundleTemplate) {
                    data.closest('.subscription-wrapper').siblings('.js-bundle-selector').find('.pdp-bundle-selector').html(response.product.customBundleTemplate);
                }
            } else {
                if (response.product.customPDPBundleTemplate) {
                    data.closest('.subscription-wrapper').siblings('.js-bundle-selector').find('.pdp-bundle-selector').html(response.product.customPDPBundleTemplate);
                }
            }
            $.spinner().stop();
        },
        error: function (err) {
            console.log(err);
            $.spinner().stop();
        }
    });
};

function quickViewSwiper() {
    var Swiper = require('swiper');
    new Swiper('.pdp-swiper-large', {
        zoom: false,
        slidesPerView: 5,
        nextButton: '.swiper-button-next',
        prevButton: '.swiper-button-prev',
    });
    new Swiper('.pdp-swiper-small', {
        zoom: false,
        pagination: '.swiper-pagination',
        slidesPerView: 1,
        slidesPerColumn: 1,
        slidesPerGroup: 1,
        centeredSlides: true,
        paginationClickable: true,
        keyboardControl: false,
        mousewheelControl: false,
        mousewheelForceToAxis: true,
        grabCursor: true
    });
}

module.exports = {
    attributeSelect: attributeSelect,
    methods: {
        editBonusProducts: function (data) {
            chooseBonusProducts(data);
        }
    },

    focusChooseBonusProductModal: function () {
        $('body').on('shown.bs.modal', '#chooseBonusProductModal', function () {
            $('#chooseBonusProductModal').siblings().attr('aria-hidden', 'true');
            $('#chooseBonusProductModal .close').focus();
        });
    },

    onClosingChooseBonusProductModal: function () {
        $('body').on('hidden.bs.modal', '#chooseBonusProductModal', function () {
            $('#chooseBonusProductModal').siblings().attr('aria-hidden', 'false');
        });
    },

    trapChooseBonusProductModalFocus: function () {
        $('body').on('keydown', '#chooseBonusProductModal', function (e) {
            var focusParams = {
                event: e,
                containerSelector: '#chooseBonusProductModal',
                firstElementSelector: '.close',
                lastElementSelector: '.add-bonus-products'
            };
            focusHelper.setTabNextFocus(focusParams);
        });
    },

    colorAttribute: function () {
        $(document).on('click', '[data-attr="size"]', function (e) {
            e.preventDefault();

            if ($(this).hasClass('disabled')) {
                return;
            }
            var $productContainer = $(this).closest('.set-item');
            if (!$productContainer.length) {
                $productContainer = $(this).closest('.product-detail');
            }

            attributeSelect($(this).attr('data-url'), $productContainer);
        });
    },

    selectAttribute: function () {
        $(document).on('change', 'select[class*="select-"], .options-select', function (e) {
            e.preventDefault();

            var $productContainer = $(this).closest('.set-item');
            if (!$productContainer.length) {
                $productContainer = $(this).closest('.product-detail');
            }
            attributeSelect(e.currentTarget.value, $productContainer);
        });
    },

    availability: function () {
        $(document).on('change', '.quantity-select', function (e) {
            e.preventDefault();

            var $productContainer = $(this).closest('.product-detail');
            if (!$productContainer.length) {
                $productContainer = $(this).closest('.modal-content').find('.product-quickview');
            }

            if ($('.bundle-items', $productContainer).length === 0) {
                attributeSelect($(e.currentTarget).find('option:selected').data('url'),
                    $productContainer);
            }
        });
    },

    addToCart: function () {
        $(document).on('click', 'button.add-to-cart, button.add-to-cart-global', function () {
            var addToCartUrl;
            var pid;
            var pidsObj;
            var setPids;

            $('body').trigger('product:beforeAddToCart', this);

            if ($('.set-items').length && $(this).hasClass('add-to-cart-global')) {
                setPids = [];

                $('.product-detail').each(function () {
                    if (!$(this).hasClass('product-set-detail')) {
                        setPids.push({
                            pid: $(this).find('.product-id').text(),
                            qty: $(this).find('.quantity-select').val(),
                            options: getOptions($(this))
                        });
                    }
                });
                pidsObj = JSON.stringify(setPids);
            }

            pid = getPidValue($(this));

            var $productContainer = $(this).closest('.product-detail');
            if (!$productContainer.length) {
                $productContainer = $(this).closest('.quick-view-dialog').find('.product-detail');
            }

            addToCartUrl = getAddToCartUrl();

            var form = {
                pid: pid,
                pidsObj: pidsObj,
                childProducts: getChildProducts(),
                quantity: getQuantitySelected($(this)),
                isSubscription: $('[name="hasSmartOrderRefill"]').filter(':checked').val()
            };

            if (!$('.bundle-item').length) {
                form.options = getOptions($productContainer);
            }

            $(this).trigger('updateAddToCartFormData', form);
            if (addToCartUrl) {
                $.ajax({
                    url: addToCartUrl,
                    method: 'POST',
                    data: form,
                    success: function (data) {
                        handlePostCartAdd(data);
                        $('body').trigger('product:afterAddToCart', data);
                        $.spinner().stop();
                        miniCartReportingUrl(data.reportingURL);
                    },
                    error: function () {
                        $.spinner().stop();
                    }
                });
            }
        });
    },
    selectBonusProduct: function () {
        $(document).on('click', '.select-bonus-product', function () {
            var $choiceOfBonusProduct = $(this).parents('.choice-of-bonus-product');
            var pid = $(this).data('pid');
            var maxPids = $('.choose-bonus-product-dialog').data('total-qty');
            var image = $choiceOfBonusProduct.find('.carousel-image.carousel-selected').attr('src') || $choiceOfBonusProduct.find('.carousel-image.carousel-selected').attr('data-src');
            var submittedQty = parseInt($choiceOfBonusProduct.find('input.quantity').val(), 10);
            var weight = $choiceOfBonusProduct.find('.bundle-selector-weight').text();
            var removeProductIcon = $choiceOfBonusProduct.parents().siblings('.modal-footer').find('.selected-bonus-products').attr('data-remove-product-icon');
            var totalQty = 0;
            $.each($('#chooseBonusProductModal .selected-bonus-products .selected-pid'), function () {
                totalQty += $(this).data('qty');
            });
            totalQty += submittedQty;
            var optionID = $choiceOfBonusProduct.find('.product-option').data('option-id');
            var valueId = $choiceOfBonusProduct.find('.options-select option:selected').data('valueId');
            var warningImg = $('.selected-items-msg').attr('data-warning-img');
            var successImg = $('.selected-items-msg').attr('data-success-img');
            if (totalQty <= maxPids) {
                $('.selected-bonus-products, .selected-items-msg').removeClass('d-none');
                var selectedBonusProductHtml = ''
                + '<div class="selected-pid row" '
                + 'data-pid="' + pid + '"'
                + 'data-qty="' + submittedQty + '"'
                + 'data-optionID="' + (optionID || '') + '"'
                + 'data-option-selected-value="' + (valueId || '') + '"'
                + 'data-weight="' + weight + '"'
                + '>'
                + '<div class="col-3 d-flex flex-column justify-content-center" >'
                + '<div class="item-image">'
                + '<img class="product-image m-0" src="' + image.split("?")[0] + '?sw=100&sfrm=png' + '" />'
                + '</div>'
                + '</div>'
                + '<div class="col-6 d-flex flex-column justify-content-center bonus-product-name" >'
                + '<div class="name">'
                + $choiceOfBonusProduct.find('.name').html()
                + '</div>'
                + '<div class="product-weight">' + submittedQty + ' x ' + weight + '</div>'
                + '</div>'
                + '<div class="col-3 d-flex justify-content-end align-items-center">'
                + '<div class="remove-bonus-product">'
                + '<img class="remove-icon" src="' + removeProductIcon + '" alt="" />'
                + '</div>'
                + '</div>'
                $('#chooseBonusProductModal .selected-bonus-products').append(selectedBonusProductHtml);
                $('.pre-cart-products').html(totalQty);
                $('.selected-msg').removeClass('alert-danger').addClass('alert-success');
                $('.warning-icon-wrappper').empty().append('<img class="mr-2" src="' + successImg + '" alt="" />');
            } else {
                $('.selected-msg').addClass('alert-danger').removeClass('alert-success');

                if ($('.warning-icon-wrappper').empty()) {
                    $('.warning-icon-wrappper').append('<img class="mr-2" src="' + warningImg + '" alt="" />');
                }
            }
        });
    },
    removeBonusProduct: function () {
        $(document).on('click', '.selected-pid .remove-bonus-product', function () {
            $(this).closest('.selected-pid.row').remove();
            var $selected = $('#chooseBonusProductModal .selected-bonus-products .selected-pid');
            var count = 0;
            if ($selected.length) {
                $selected.each(function () {
                    count += parseInt($(this).closest('.selected-pid.row').data('qty'), 10);
                });
            }

            $('.pre-cart-products').html(count);
            $('.selected-bonus-products .bonus-summary').removeClass('alert-danger');

            if (count == 0) {
                $('.selected-bonus-products').addClass('d-none')
            }
        });
    },
    enableBonusProductSelection: function () {
        $('body').on('bonusproduct:updateSelectButton', function (e, response) {
            $('button.select-bonus-product', response.$productContainer).attr('disabled',
                (!response.product.readyToOrder || !response.product.available));
            var pid = response.product.id;
            $('button.select-bonus-product', response.$productContainer).data('pid', pid);
        });
    },
    showMoreBonusProducts: function () {
        $(document).on('click', '.show-more-bonus-products', function () {
            var url = $(this).data('url');
            $('.modal-content').spinner().start();
            $.ajax({
                url: url,
                method: 'GET',
                success: function (html) {
                    var parsedHtml = parseHtml(html);
                    $('.modal-body').append(parsedHtml.body);
                    $('.show-more-bonus-products:first').remove();
                    $('.modal-content').spinner().stop();
                },
                error: function () {
                    $('.modal-content').spinner().stop();
                }
            });
        });
    },
    addBonusProductsToCart: function () {
        $(document).on('click', '.add-bonus-products', function () {
            var $readyToOrderBonusProducts = $('.choose-bonus-product-dialog .selected-pid');
            var queryString = '?pids=';
            var url = $('.choose-bonus-product-dialog').data('addtocarturl');
            var pidsObject = {
                bonusProducts: []
            };

            $.each($readyToOrderBonusProducts, function () {
                var qtyOption =
                    parseInt($(this)
                        .data('qty'), 10);

                var option = null;
                if (qtyOption > 0) {
                    if ($(this).data('optionid') && $(this).data('option-selected-value')) {
                        option = {};
                        option.optionId = $(this).data('optionid');
                        option.productId = $(this).data('pid');
                        option.selectedValueId = $(this).data('option-selected-value');
                    }
                    pidsObject.bonusProducts.push({
                        pid: $(this).data('pid'),
                        qty: qtyOption,
                        options: [option]
                    });
                    pidsObject.totalQty = parseInt($('.pre-cart-products').html(), 10);
                }
            });
            queryString += JSON.stringify(pidsObject);
            queryString = queryString + '&uuid=' + $('.choose-bonus-product-dialog').data('uuid');
            queryString = queryString + '&pliuuid=' + $('.choose-bonus-product-dialog').data('pliuuid');
            $.spinner().start();
            $.ajax({
                url: url + queryString,
                method: 'POST',
                success: function (data) {
                    $.spinner().stop();
                    if (data.error) {
                        $('#chooseBonusProductModal').modal('hide');
                        if ($('.add-to-cart-messages').length === 0) {
                            $('body').append('<div class="add-to-cart-messages"></div>');
                        }
                        $('.add-to-cart-messages').append(
                            '<div class="alert alert-danger add-to-basket-alert text-center"'
                            + ' role="alert">'
                            + data.errorMessage + '</div>'
                        );
                        setTimeout(function () {
                            $('.add-to-basket-alert').remove();
                        }, 3000);
                    } else {
                        $('.configure-bonus-product-attributes').html(data);
                        $('.bonus-products-step2').removeClass('hidden-xl-down');
                        $('#chooseBonusProductModal').modal('hide');

                        if ($('.add-to-cart-messages').length === 0) {
                            $('body').append('<div class="add-to-cart-messages"></div>');
                        }
                        $('.minicart-quantity').html(data.totalQty);
                        $('.add-to-cart-messages').append(
                            '<div class="alert alert-success add-to-basket-alert text-center"'
                            + ' role="alert">'
                            + data.msgSuccess + '</div>'
                        );
                        setTimeout(function () {
                            $('.add-to-basket-alert').remove();
                            if ($('.cart-page').length) {
                                location.reload();
                            }
                        }, 1500);
                    }
                },
                error: function () {
                    $.spinner().stop();
                }
            });
        });
    },
    updatePriceOnQuantity: function () {
        $(document).on('change', '.pdp-quantity input.quantity', function () {
            var $this = $(this);
            var $productContainer = $(this).closest('.product-detail');
            if (!$productContainer.length) {
                $productContainer = $(this).closest('.modal-content').find('.product-quickview');
            }

            var url = $(this).attr('data-qtyurl');
            var val = $(this).val();
            url = url + '&quantity=' + val + '&SORprice=' + $(this).closest('.pdp-cart-and-price').siblings('.js-bundle-selector').find('.OsfSorRefillProductYes').is(":checked");
            var $addToCartButton = $('.add-to-cart');
            $.spinner().start();
            $.ajax({
                url: url,
                method: 'GET',
                success: function (data) {
                    var renderedHtml = $.parseHTML(data.product.renderedPriceQuantity);
                    // Update price UI
                    $this.closest('.quantity-wrapper').siblings('.pdp-cart-and-price').find('.pdp-price .price.row .col').html(renderedHtml)
                    // Update discount badge
                    if (data.product.discountPercentage) {
                        $('body .pdp-price .price-discount').html(data.product.discountPercentage + '%');
                    }

                    updateAvailability(data, $productContainer);
                    $('button.add-to-cart, button.add-to-cart-global, button.update-cart-product-global').trigger('product:updateAddToCart', {
                        product: data.product, $productContainer: $productContainer
                    }).trigger('product:statusUpdate', data.product);

                    $addToCartButton.data('item-quantity', val);

                    const quickView = $('.product-quickview');
                    if (quickView.length > 0 && quickView.is(':visible')) {
                        if (data.product.customBundleTemplate) {
                            $this.closest('.subscription-wrapper').siblings('.js-bundle-selector').find('.pdp-bundle-selector').html(data.product.customBundleTemplate);
                        }
                    } else {
                        if (data.product.customPDPBundleTemplate) {
                            $this.closest('.subscription-wrapper').siblings('.js-bundle-selector').find('.pdp-bundle-selector').html(data.product.customPDPBundleTemplate);
                        }
                    }

                    $.spinner().stop();
                },
                error: function (err) {
                    console.log(err);
                    $.spinner().stop();
                }
            });
        });
    },

    customAttributeSelection: function () {
        $(document).on('click', '.pdp-bundle-selector', function (e) {
            if ($(e.target).closest('.tab-bundle').hasClass('disabled')) {
                return;
            }
            var $productContainer = $(this).closest('.set-item');
            if (!$productContainer.length) {
                $productContainer = $(this).closest('.product-detail');
            }

            var url = $(this).find(".selected").attr('data-url');
            var pid = $(this).find(".selected").attr('data-custom-pid');
            var qty = $('.quantity').val();

            if (url && pid) {
                attributeSelect(url + '?pid=' + pid + '&quantity=' + qty, $productContainer);
            }

        });
    },

    getPidValue: getPidValue,
    getQuantitySelected: getQuantitySelected,
    miniCartReportingUrl: miniCartReportingUrl,
    applySORDiscount: applySORDiscount,
    quickViewSwiper: quickViewSwiper
};
