'use strict';

module.exports = function changeMinicartIcon(count) {
    if (count > 0) {
        $('.minicart-total').removeClass('d-none').addClass('active');
        $('.cart-not-empty').removeClass('d-none');
    } else {
        $('.minicart-total').addClass('d-none').removeClass('active');
        $('.cart-not-empty').addClass('d-none');
    }
}
